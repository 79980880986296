import registerBlock from "../registerBlock";

// noinspection JSUnusedGlobalSymbols
export { default as EditableText } from "./editable-text";

export { default as EditableHeading } from "./editable-heading";

// noinspection JSUnusedGlobalSymbols
export { default as EditableImg } from "./editable-img";

// noinspection JSUnusedGlobalSymbols
export { default as ColorToolbarButton } from "./color-toolbar-picker";

// Not exported, because loaded on the fly by webpack if necessary
export { default as FallbackBlock } from "./fallback-block";

//Temporary fallback
// noinspection JSUnusedGlobalSymbols
export { default as EditableDiv } from "./editable-text";

// Smart Parallax
export { default as SmartParallax } from "./smart-parallax";

// Smart Form
export { default as SmartForm } from "./smart-form";

import Select from "./smart-form/select";
import Checkbox from "./smart-form/checkbox";
import Input from "./smart-form/input";
import TextArea from "./smart-form/textarea";

let smartformRegistered = false;

export function registerSmartFormBlocks() {
  if (!smartformRegistered) {
    registerBlock("select", () => Select);
    registerBlock("checkbox", () => Checkbox);
    registerBlock("input", () => Input);
    registerBlock("textarea", () => TextArea);
  }
  smartformRegistered = true;
}
