import React, { HTMLProps } from "react";
import { useSmartProp, useEditor } from "../..";

import HeadingLevelDropdown, { HeadingLevel } from "./HeadingLevelDropdown";

import * as BlockEditor from "@wordpress/block-editor";
import type { RichText as RT } from "@wordpress/block-editor";

const RichText = BlockEditor?.RichText as RichTextOverride;
const BlockControls = BlockEditor?.BlockControls as typeof BlockEditor.BlockControls;

declare type RichTextOverride = {
  <T extends keyof HTMLElementTagNameMap = "div">(
    props: RT.Props<T> & {
      allowedFormats: any;
    }
  ): JSX.Element;
  /**
   * Should be used in the `save` function of your block to correctly save rich text content.
   */
  Content<T extends keyof HTMLElementTagNameMap = "div">(
    props: RT.ContentProps<T>
  ): JSX.Element;
  isEmpty(value: string | string[]): boolean;
};

export type EditableHeadingSmartProp = {
  content: string;
  headingLevel: HeadingLevel;
};
export interface EditableTextProps<T extends keyof HTMLElementTagNameMap>
  extends RT.Props<T> {
  smartProp: string;
  tagProps: HTMLProps<T>;
  render: (value: string, props: HTMLProps<T>, TagName: T) => React.ReactNode;
}
type Heading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export default function EditableHeading({
  smartProp,
  className = undefined,
  style = undefined,
  tagProps = {},
  render = function (value, props = {}, TagName) {
    return React.createElement(TagName, {
      dangerouslySetInnerHTML: { __html: value },
      ...props,
    });
  },
  ...props
}: EditableTextProps<Heading>) {
  const [value, updateValue] = useSmartProp(smartProp);
  const { isEditing } = useEditor();
  const tagName = `h${(value ?? { headingLevel: 1 }).headingLevel}` as
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
  if (isEditing) {
    return (
      <div className={className}>
        <BlockControls>
          <HeadingLevelDropdown
            selectedLevel={(value ?? { headingLevel: 1 }).headingLevel}
            onChange={(headingLevel) => {
              updateValue({ ...(value ?? { content: "" }), headingLevel });
            }}
          />
        </BlockControls>
        <RichText
          tagName={tagName}
          placeholder={"..."}
          allowedFormats={[]}
          value={value?.content}
          className={"editableInput"}
          onChange={(content) => {
            updateValue({ ...(value ?? { headingLevel: 1 }), content });
          }}
          {...props}
        />
      </div>
    );
  } else {
    return render(
      value?.content ?? "",
      {
        className,
        style,
        ...tagProps,
      },
      tagName
    );
  }
}
